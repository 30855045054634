<template>
    <div class="verification-badges" ref="main" @scroll="onScroll">
        <StackRouterHeaderBar :title="$translate('BADGE')" :showTitle="showTitle" />
        <h1 class="title">{{ $translate('BADGE') }}</h1>
        <section class="section overview">
            <div class="m-b-24 p-relative">
                <img src="@/assets/images/verification-badge/active/empty.svg" />
                <span class="count p-absolute">{{ activeBadges }}</span>
            </div>
            <div class="flex items-center">
                <p class="acquired m-b-8">획득한 뱃지</p>
                <p class="acquired-desc">뱃지를 획득할수록,<br />매력적인 이성을 만날 확률이 높아져요!</p>
            </div>
        </section>
        <template v-if="dataLoaded">
            <section
                class="section"
                :class="category"
                v-for="category in Object.keys(verificationBadge)"
                :key="category"
            >
                <h2 class="badge-title m-b-24">{{ $translate(category.toUpperCase()) }}</h2>
                <div class="badge-grid">
                    <div
                        class="item"
                        :class="isActiveOrNot(badge)"
                        v-for="badge in verificationBadge[category]"
                        :key="badge.id"
                        @click="onClickBadge(badge)"
                    >
                        <div class="p-relative m-b-8">
                            <div v-lazy:background-image="imagePath(badge)" class="icon-img" />
                            <img
                                v-if="customImage(badge)"
                                v-lazy="customImage(badge)"
                                class="custom-img"
                                :class="{ gray: isUnderReview(badge) }"
                            />
                            <div v-if="isUnderReview(badge)" class="under-review">심사중</div>
                        </div>
                        <span class="name">{{ badge.name }}</span>
                    </div>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar.vue'

export default {
    name: 'VerificationBadgesPage',
    components: { StackRouterHeaderBar },
    props: ['from'],
    data: () => ({
        dataLoaded: false,
        verificationBadge: {
            attraction: [],
            career: [],
            financial: [],
            manner: [],
            profile: [],
        },
        showTitle: false,
    }),
    watch: {
        myVerificationBadges: {
            deep: true,
            handler() {
                this.verificationBadge = this.$copy(this.verificationBadge)
            },
        },
    },
    computed: {
        activeBadges() {
            return this.myVerificationBadges.filter(b => b.confirmed).length
        },
        myVerificationBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
    },
    methods: {
        async init() {
            try {
                this.$loading(true)
                const loadVerificationBadges = () => this.$store.dispatch('loadVerificationBadges')
                const loadMyVerificationBadges = () => this.$store.dispatch('loadMyVerificationBadges')

                const [badges] = await Promise.all([loadVerificationBadges(), loadMyVerificationBadges()])

                badges.forEach(badge => {
                    this.verificationBadge[badge.category].push(badge)
                })

                if (this.$isTester()) {
                    // 초대 관련된 항목들이 profile에 있어서 아예 삭제
                    delete this.verificationBadge.profile
                }

                this.dataLoaded = true

                if (this.from && this.from === 'TodayMeetingNeedCert') {
                    const dom = this.$refs.main

                    if (dom) {
                        await this.$waitFor(200)
                        this.$scroll.down(dom, false)
                    }
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        isActiveOrNot(badge) {
            return this.myVerificationBadges.find(b => b.verification_badge_id === badge.id && b.confirmed)
                ? 'active'
                : 'inactive'
        },
        isUnderReview(badge) {
            const mVB = this.myVerificationBadges.find(b => b.verification_badge_id === badge.id)
            if (!mVB) return false

            return !mVB.confirmed
        },
        imagePath(badge) {
            return require(`@/assets/images/verification-badge/${this.isActiveOrNot(badge)}/${badge.image_name}.svg`)
        },
        customImage(badge) {
            const myBadge = this.myVerificationBadges.find(mBadge => {
                return mBadge.verification_badge_id === badge.id
            })

            if (myBadge && badge.display_method === 'photo') {
                return myBadge.photos[0]
            }
        },
        onClickBadge(badge) {
            const myBadge = this.myVerificationBadges.find(b => b.verification_badge_id === badge.id)

            if (myBadge) {
                if (!myBadge.confirmed) {
                    this.$modal.basic({
                        title: '심사 중',
                        body: '심사에는 1~2영업일이 소요될 수 있으며, 심사 결과는 알림으로 보내드립니다.',
                        buttons: [
                            {
                                label: '확인',
                                class: 'btn-default',
                            },
                        ],
                    })
                } else {
                    myBadge.description = badge.description
                    myBadge.image_name = badge.image_name
                    myBadge.category = badge.category
                    this.$modal.custom({
                        component: 'ModalBadgeInfo',
                        options: {
                            badge: myBadge,
                            from: 'myPage',
                        },
                    })
                    // this.$toast.success('이미 획득한 뱃지에요')
                }

                return
            }

            if (badge.name === '실명인증') {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'BadgePage_Click_VerificationBadge',
                    },
                })
            }

            const realNameBadge = this.verificationBadge.manner.find(b => b.name === '실명인증')
            const myRealNameBadge = this.myVerificationBadges.find(b => b.name === '실명인증')
            if (
                ['manner', 'career', 'financial'].includes(badge.category) &&
                badge.verification_type !== 'auto' &&
                !myRealNameBadge
            ) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Alert_RequestNameverif',
                    },
                })

                this.$modal
                    .basic({
                        body: `'${badge.name}' 뱃지를 획득하기 위해선 먼저 실명인증이 필요합니다`,
                        buttons: [
                            {
                                label: '다음에 하기',
                                class: 'btn-default',
                            },
                            {
                                label: '실명인증하기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$stackRouter.push({
                                name: 'VerificationBadgesCreatePage',
                                props: {
                                    badge: realNameBadge,
                                },
                            })
                        }
                    })
            } else {
                this.$stackRouter.push({
                    name: 'VerificationBadgesCreatePage',
                    props: {
                        badge,
                    },
                })
            }
        },
        onScroll(event) {
            if (event.target.scrollTop > 50) {
                this.showTitle = true
            } else {
                this.showTitle = false
            }
        },
    },
    mounted() {
        this.init()

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_BadgePage',
            },
        })
    },
}
</script>

<style lang="scss" scoped>
.verification-badges {
    .title {
        margin-left: 16px;
        font-size: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: solid 1px $grey-02;

        &.overview {
            padding: 32px 40px;

            .count {
                font-size: 40px;
                color: $purple-primary;
                left: 50%;
                top: 46%; // 50%가 원래 이미지 정가운데인데 원에 중앙에 위치해야해서.. 디자인qa
                transform: translate(-50%, -50%);

                @include f-bold;
            }

            .acquired {
                font-size: 18px;
                color: black;

                @include f-medium;
            }

            .acquired-desc {
                color: $grey-08;
                text-align: center;
                line-height: normal;
            }
        }

        &:not(.overview) {
            padding: 40px 24px;
        }

        .badge-title {
            font-size: 18px;
            color: black;
            font-weight: 500;

            @include f-medium;
        }

        .badge-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            row-gap: 24px;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .name {
                    white-space: nowrap;
                }

                &.inactive > .name {
                    color: $grey-04;

                    @include f-medium;
                }

                &.active > .name {
                    color: $purple-primary;

                    @include f-bold;
                }

                .icon-img {
                    width: 74px;
                    height: 74px;
                    background-size: cover;
                }

                .custom-img {
                    position: absolute;
                    width: 62px;
                    height: 62px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    &.gray {
                        filter: opacity(25%);
                    }
                }

                .under-review {
                    position: absolute;
                    width: 58px;
                    height: 28px;
                    color: white;
                    background-color: $purple-primary;
                    opacity: 0.9;
                    border-radius: 14px;
                    font-size: 14px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    @include f-bold;
                    @include center;
                }
            }
        }
    }
}
</style>
